import 'core-js/actual/promise';
import request from "superagent";

const context = mineralFinderContext;

window.mineralFinderState = {
	searchUrl: context.searchPreviewUrl,
	showAll: {
		class: context.showAllClass,
	},
	form: {
		class: context.formClass,
		page: 1, // pagination
		pageId: context.pageId, // to only show sub-pages
	},
	listing: {
		isLoading: true,
		pagination: {
			linkClass: context.paginationLinkClass,
		},
	},
};

// START
$(() => {
	const formElements = document.querySelectorAll(`.${mineralFinderState.form.class}`);

	formElements.forEach(element => {
		const formElementID = element.id;
		const listingElementID = formElementID.replace('search-', 'result-');
		const showAllElementID = formElementID.replace('search-', 'show-all-');

		const formEl = $(`#${formElementID}`);

		if (formEl.length > 0) {
			// Load results on page load if form is present on the page.
			refreshResults(formElementID, listingElementID, showAllElementID);
		}

		// show all -> leave preview mode
		$(`#${showAllElementID}`).on("click", function (event) {
			event.preventDefault();
			showFormAndResults(formElementID, listingElementID, showAllElementID);
		});

		// form changed
		$(`#${formElementID}`).on(
			"keyup change paste",
			"input, select, textarea",
			function (event) {
				event.preventDefault();
				event.stopPropagation()
				mineralFinderState.form.page = 1;
				refreshResults(formElementID, listingElementID, showAllElementID);
			}
		);

		// or form submitted (for screen readers since the field is hidden)
		$(`#${formElementID}`).submit(function (event) {
			event.preventDefault();
			event.stopPropagation()
			mineralFinderState.form.page = 1;
			refreshResults(formElementID, listingElementID, showAllElementID);
		});
	});
});

function showFormAndResults(formElementID, listingElementID, showAllElementID) {
	$(`#${showAllElementID}`).hide();
	mineralFinderState.searchUrl = context.searchUrl; // not the preview anymore
	refreshResults(formElementID, listingElementID, showAllElementID);
	$(`#${formElementID}`).show();
}

function refreshResults(formElementID, listingElementID, showAllElementID) {
	/* Show the results corresponding to the current form */
	mineralFinderState.listing.isLoading = true;

	window.get_csrf_token_value().then(csrfToken => {
		var dataArray = $(`#${formElementID} :input`).serializeArray();
		dataArray[dataArray.length] = {
			name: "page",
			value: mineralFinderState.form.page,
		};
		dataArray[dataArray.length] = {
			name: "parent_page_id",
			value: mineralFinderState.form.pageId,
		};
		dataArray[dataArray.length] = {
			name: "csrfmiddlewaretoken",
			value: csrfToken,
		};

		//build "dict"
		var data = {};
		$.each(dataArray, function (key, value) {
			data[value["name"]] = value["value"];
		});

		request
			.post(mineralFinderState.searchUrl)
			.type("form")
			.send(data)
			.then((result) => {
				if (result.body.success) {
					$(`#${listingElementID}`).html(result.body.html);
					handlePageClicks(formElementID, listingElementID, showAllElementID); // pagination is part of the results
					mineralFinderState.listing.isLoading = false;
				} else {
					console.log("Incorrect search");
					mineralFinderState.listing.isLoading = false;
				}
			})
			.catch((err) => {
				console.log(err.message, []);
				mineralFinderState.listing.isLoading = false;
			});
	});
}

function handlePageClicks(formElementID, listingElementID, showAllElementID) {
	$(`#${listingElementID} .${mineralFinderState.listing.pagination.linkClass}`).on(
		"click",
		function (event) {
			event.preventDefault();

			// console.log(event.target.href);
			let page = event.target.href.split("page=")[1]
				? event.target.href.split("page=")[1]
				: 1;
			mineralFinderState.form.page = page;

			refreshResults(formElementID, listingElementID, showAllElementID);
		}
	);
}
